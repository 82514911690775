//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default{

    name: "instagram",
    data(){
        return {
             user_id: 17841448485979724,
             instagram_data: []
        }
    },
    mounted() {
        
        if (!process.server)
        {
            
            
            let instafeedToken = "https://ig.instant-tokens.com/users/e5434ff2-d915-4808-a307-04ed962fd01e/instagram/17841448485979724/token?userSecret=7436gp8tf1nhsge1jifl0k";
            
            let self = this;
            
            fetch(instafeedToken, {
                method: 'GET'
            }).then(function(response)
            {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            }).then(function(data){ 
            fetch('https://graph.instagram.com/' + self.user_id + '/media?fields=id,thumbnail_url,permalink,%20caption,link,media_url&access_token=' + data.Token, 
                {
                    method: 'GET'
                }
            ).then(function(response){
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            }).then(function(response){
                self.instagram_data = response.data.slice(0, 4);
            });

            console.log("?");
    
        });

    }
    }
}

